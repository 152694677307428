<template>
	<a
		@click="changePage"
		:title="title"
		:href="getUrl('ricetta/' + slug)"
		class="recipe-card content-shadow"
	>
		<div>
			<div
				class="recipe-image"
				:style="'background-image: url(' + image + ')'"
			/>
			<br class="clear" />
			<div class="recipe-wrap">
				<div class="categories color-aili-black tiny">
					<div class="post-type">Ricetta</div>

					<div class="separator"></div>
					<div
						class="category"
						v-for="(category, j) in categories"
						:key="j"
					>
						<span class="category-name">{{ category.name }}</span>
						<span
							class="category-comma"
							v-if="j < categories.length - 1"
							>,</span
						>
					</div>
				</div>
				<h4 class="color-aili-pink font-size-20" v-html="title" />
				<div class="author-wrap">
					<div
						class="author-image-single"
						v-if="author.images"
						:style="
							'background-image: url(' +
								author.images.thumbnail +
								')'
						"
					></div>
					<h4 class="author-name color-aili-black font-size-14">
						{{ author.first_name + " " + author.last_name }}
					</h4>
				</div>
			</div>
			<div
				class="preview_data"
				v-if="
					preview_data.difficulty ||
						preview_data.people ||
						preview_data.duration
				"
			>
				<div class="columns-4" v-if="preview_data.difficulty">
					<RecipeIconTiny
						:value="preview_data.difficulty"
						name="difficulty"
					/>
				</div>
				<div class="columns-4" v-if="preview_data.people">
					<RecipeIconTiny
						:value="preview_data.people"
						name="people"
					/>
				</div>
				<div class="columns-4" v-if="preview_data.duration">
					<RecipeIconTiny
						:value="preview_data.duration"
						name="duration"
					/>
				</div>
				<br class="clear" />
			</div>
		</div>
	</a>
</template>
<script>
import RecipeIconTiny from "@/components/ui/RecipeIconTiny.vue";

export default {
	props: [
		"author",
		"title",
		"preview_data",
		"slug",
		"image",
		"refresh",
		"categories"
	],
	components: {
		RecipeIconTiny
	},
	methods: {
		changePage(e) {
			e.preventDefault();
			if (this.refresh) {
				window.location.href = "/ricetta/" + this.slug;
			} else {
				this.goTo("Recipe", { slug: this.slug });
			}
		}
	}
};
</script>

<style lang="scss" scoped>
a {
	text-decoration: none;
}
.author-name {
	line-height: 40px;
	margin-bottom: 25px;
	margin-left: 50px;
	line-height: 1.4;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
}
.author-wrap {
	position: relative;
}
.author-image-single {
	margin-bottom: 15px;
	height: 40px;
	width: 40px;
	background-size: contain;
	border-radius: 100%;
	margin-right: 14px;
}
.categories {
	margin-bottom: 6px;
	font-size: 12px;
	text-transform: uppercase;
}
.recipe-card {
	cursor: pointer;
	background: #fff;
	position: relative;
	display: inline-block;
	width: 100%;
	/* width: 30%; */
	/* min-width: 300px; */
	/* margin-bottom: $spacing-1; */
	/* min-height: 350px; */
	/* margin-right: $spacing-0; */


	.preview_data {
		border-top: 1px solid #fed3e7;
		padding-top: 10px;
		bottom: 10px;
		width: 100%;
		position: absolute;
	}
	.columns-4 {
		float: left;
		width: 33%;
	}
	a {
		display: inline-block;
		width: 100%;
		text-decoration: none;
	}

	.recipe-image {
		float: left;
		width: 100%;
		padding-top: 60%;
		background-position: center center;
		background-size: cover;
		background-repeat: no-repeat;
	}
	.recipe-wrap {
		margin-bottom: 80px;
		position: relative;
		padding: $spacing-0;
	}
	h4 {
		margin-bottom: 15px;
	}
}
</style>
